import _ from "lodash";
import * as React from "react";
import { useGetOne, useNotify, usePermissions } from "react-admin";
import { Typography, Box, Button } from "@material-ui/core";
import { ShippingPurchase } from "./shipping-purchase";
import { resetHistory } from "../../utils/reset-history";
import { Spacer } from "../generics/spacer";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";
import { useUserContext } from "../../contexts/user-context";
import {
  BooleanRoles,
  extractAuthClaims,
  hasPermissions
} from "../../ts/interfaces/role-interface";
import { useBarcode } from "../../contexts/barcode-context";
import { PopupNotificationType } from "../popup-notification/popup-notification";
import { ShipmentData } from "../../ts/interfaces/shipping-interface";

interface IShippingRates {
  requestData: unknown;
  bundledOrderId: string;
  currentRate: { shipmentId: string; rate: ShippingRate };
  setCurrentRate: React.Dispatch<
    React.SetStateAction<{ shipmentId: string; rate: ShippingRate }>
  >;
}

export interface ShippingRate {
  carrier: string;
  currency: string;
  deliverDate: string;
  deliveryDays: number;
  deliveryGuarantee?: boolean | null;
  estDeliveryDays: number;
  id: string;
  rate: string;
  service: string;
  shipmentId: string;
}

interface SellerPopUpInput {
  rate: ShippingRate | null;
  setOpenSellerConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentRate: React.Dispatch<
    React.SetStateAction<{ shipmentId: string; rate: ShippingRate }>
  >;
}

const SellerConfirmModal = ( properties: SellerPopUpInput ): JSX.Element =>
{
  const notify = useNotify();

  const handleConfirm = () =>
  {
    if ( _.isNil( properties.rate ) )
    {
      notify( "Rate should be selected!" );
      properties.setOpenSellerConfirmModal( false );
      return;
    }

    properties.setCurrentRate( {
      shipmentId: properties.rate.id,
      rate: properties.rate,
    } );
    properties.setOpenSellerConfirmModal( false );
  };

  const handleClose = () =>
  {
    properties.setOpenSellerConfirmModal( false );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      marginTop={2}
      flexDirection="column"
    >
      <Typography>{`The shipping cost for this order is ${properties.rate.currency} $${properties.rate.rate}.`}</Typography>
      <Spacer />
      <Box
        sx={{ width: "50%" }}
        display="flex"
        justifyContent="space-around"
        marginTop={2}
        flexDirection="row"
      >
        <Button onClick={handleConfirm}>Confirm</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </Box>
    </Box>
  );
};

export const ShippingRates = ( properties: IShippingRates ): JSX.Element =>
{
  const notify = useNotify();
  const { requestData, bundledOrderId } = properties;
  const { setNotification } = useBarcode();
  const [rates, setRates] = React.useState( [] );
  const [shipment, setShipment] = React.useState( null );

  // Determine the seller type
  const { loaded, permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );
  const { sellerType } = useUserContext();

  // Handling confirmation popup for seller
  const [openSellerConfirmModal, setOpenSellerConfirmModal] =
    React.useState( false );
  const handleSellerConfirmModalOpen = () =>
  {
    setOpenSellerConfirmModal( true );
  };

  const handleSellerConfirmModalClose = () =>
  {
    setOpenSellerConfirmModal( false );
  };

  const [selectedRate, setSelectedRate] = React.useState( null );

  const { loading, error } = useGetOne(
    "shipping-labels",
    { requestData },
    {
      onSuccess: ( response: {
        data: {
          id: string;
          rates: ShippingRate[];
          shipmentData?: ShipmentData;
        };
      } ) =>
      {
        setRates( response.data.rates );
        setShipment( response.data.shipmentData );
        if ( response.data.rates.length === 0 )
        {
          setNotification( {
            message: "No shipping methods found",
            type: PopupNotificationType.error,
          } );
        }
      },
      onFailure: () =>
      {
        setTimeout( () =>
        {
          resetHistory();
          history.go();
        }, 2000 );
      },
    }
  );
  const parsedError = error && JSON.parse( error );
  const currentLabel = Object.keys( properties.currentRate ).length > 0;

  return (
    <>
      {!loading &&
        error &&
        setNotification( {
          message: parsedError.message,
          type: PopupNotificationType.error,
        } )}
      <Typography variant="h5" gutterBottom>
        {`Shipping Methods`}
      </Typography>
      {!currentLabel &&
        !loading &&
        rates.length > 0 &&
        rates.map( ( rate: ShippingRate ) => (
          <div key={rate.id}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography>
                {rate.carrier} {rate.service}
                {rate.estDeliveryDays && " - Delivers in "}
                {rate.estDeliveryDays && `${rate.estDeliveryDays} days`}
              </Typography>
              <div>&nbsp;&nbsp;</div>
              <Button
                color={"secondary"}
                variant={"outlined"}
                onClick={() =>
                {
                  setSelectedRate( rate );

                  // Confirmation popup for seller
                  if (
                    !hasPermissions(
                      [
                        BooleanRoles.Admin,
                        BooleanRoles.Partner,
                        BooleanRoles.Fulfillment,
                        BooleanRoles.FulfillmentReadOnly,
                        BooleanRoles.CustomerService,
                        BooleanRoles.RefundCustomerService,
                      ],
                      roleClaims
                    ) &&
                    hasPermissions( [BooleanRoles.Seller], roleClaims ) &&
                    sellerType === "Seller"
                  )
                  {
                    if ( !loaded )
                    {
                      notify( "Please try again in a moment" );
                      return;
                    }

                    handleSellerConfirmModalOpen();
                  }
                  else
                  {
                    const shipmentId = rate.shipmentId;
                    properties.setCurrentRate( { shipmentId, rate } );
                  }
                }}
              >
                {rate.currency}
                {" $"}
                {rate.rate}
              </Button>
            </Box>
            <Spacer />
          </div>
        ) )}
      {currentLabel && (
        <ShippingPurchase
          shippingId={properties.currentRate.rate.shipmentId}
          bundledOrdersId={bundledOrderId}
          rateId={properties.currentRate.rate.id}
          rate={properties.currentRate.rate}
          shipmentData={shipment}
        />
      )}
      <CustomModal
        open={openSellerConfirmModal}
        onClose={handleSellerConfirmModalClose}
      >
        <SellerConfirmModal
          setCurrentRate={properties.setCurrentRate}
          rate={selectedRate}
          setOpenSellerConfirmModal={setOpenSellerConfirmModal}
        />
      </CustomModal>
    </>
  );
};
