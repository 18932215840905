export type countries = "ca" | "us";

export type currencies = "cad" | "usd";
export interface Timestamps {
  created: string;
  updated: string;
  accepted?: string;
}

export enum FulfillmentType {
  shopthing = "ShopThing",
  seller = "Seller",
  partner = "Partner",
}

export enum SellerIdentityType {
  retailer = "Retailer",
}

export const ShippingCarriers = Object.freeze( {
  cp: {
    id: "cp",
    name: "Canada Post",
    trackingFormat:
      "https://www.canadapost.ca/trackweb/en#/search?searchFor=%1",
  },
  fedex: {
    id: "fedex",
    name: "FedEx",
    trackingFormat: "http://www.fedex.com/Tracking?tracknumbers=%1",
  },
  pu: {
    id: "pu",
    name: "Purolator",
    trackingFormat: "https://www.purolator.com/en/shipping/tracker?pins=%1",
  },
  ups: {
    id: "ups",
    name: "UPS",
    trackingFormat: "https://www.ups.com/track?loc=en_US&tracknum=%1",
  },
  usps: {
    id: "usps",
    name: "United States Postal Service(USPS)",
    trackingFormat:
      "https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=%1",
  },
} );
