import * as React from "react";
import {
  BooleanField,
  Datagrid,
  FunctionField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField
} from "react-admin";
import { Event as EventInterface } from "../../ts/interfaces/event-interface";
import { EventDetail } from "./event-detail";
import { SaveOnlyToolbar } from "../toolbars/save-only";
import { BasicPagination } from "../generics/pagination";
import { formatPrice, formatPriceWithoutCurrency } from "../../utils/primitive";
import { Product } from "../../ts/interfaces/product-interface";
import { PopupNotification } from "../popup-notification/popup-notification";
import {
  MediaBox,
  MediaContainer,
  MediaItem
} from "../generics/media-container";
import { Grid } from "@material-ui/core";
import { EditButton } from "ra-ui-materialui";
import { useHistory } from "react-router";

const ShowMediaContainer = ( { record }: { record: Product } ) => (
  <MediaBox>
    <Grid container spacing={2}>
      {record.media.map( ( media, index ) =>
      {
        const type = media.type;
        const keyName = "mediaPath";
        return (
          <Grid item xs={3} key={index}>
            <MediaContainer
              keyName={keyName}
              mediaItem={media as unknown as MediaItem}
              type={type}
            />
          </Grid>
        );
      } )}
    </Grid>
  </MediaBox>
);

export const EventShow = ( properties: EventInterface ): JSX.Element =>
{
  const history = useHistory();
  return (
    <Show {...properties}>
      <SimpleShowLayout toolbar={<SaveOnlyToolbar />}>
        <FunctionField
          addLabel={false}
          render={( event: EventInterface ) => <EventDetail record={event} />}
        />
        <FunctionField
          addLabel={false}
          render={( event: EventInterface ) =>
            event.hasProducts ? (
              <ReferenceManyField
                reference="event-products"
                target="id"
                filter={{ forDashboard: true, asCustomer: false }}
                fullWidth
                pagination={<BasicPagination />}
              >
                <Datagrid
                  expand={( props: { record: Product } ) => (
                    <ShowMediaContainer
                      record={props.record}
                      key={props.record.id}
                    />
                  )}
                >
                  <TextField sortable={false} label="Product Id" source="id" />
                  <TextField
                    sortable={false}
                    label="Brand Name"
                    source="brandName"
                  />
                  <TextField
                    sortable={false}
                    label="Product Name"
                    source="productName"
                  />
                  <TextField
                    sortable={false}
                    label="Product Description"
                    source="productDescription"
                  />
                  <TextField
                    sortable={false}
                    label="External Product ID"
                    source="externalProductId"
                  />
                  <BooleanField
                    sortable={false}
                    label="Is Active"
                    source="isActivated"
                  />
                  <BooleanField
                    sortable={false}
                    label="Is Published"
                    source="isPublished"
                  />
                  <TextField
                    sortable={false}
                    label="Shopper"
                    source="sellerHandle"
                  />
                  <FunctionField
                    label="Retail Price"
                    render={( data: Product ) =>
                      typeof data.regularPrice === "number"
                        ? ( data.currency
                          ? `${formatPrice(
                            data.regularPrice.toString(),
                            data.currency
                          )}`
                          : formatPriceWithoutCurrency(
                            data.regularPrice.toString()
                          ) )
                        : ``
                    }
                    sortable={false}
                  />
                  <FunctionField
                    label="Sale Price"
                    render={( data: Product ) =>
                      data.currency
                        ? `${formatPrice(
                          data.salePrice.toString(),
                          data.currency
                        )}`
                        : formatPriceWithoutCurrency( data.salePrice.toString() )
                    }
                    sortable={false}
                  />

                  <FunctionField
                    label=""
                    render={( data: Product ) => (
                      <EditButton
                        onClick={() =>
                        {
                          history.push( `/event-product-edit/${data.id}` );
                        }}
                      />
                    )}
                    sortable={false}
                  />
                </Datagrid>
              </ReferenceManyField>
            ) : (
              <>
                <br />
                <h3>There are no products in this event.</h3>
              </>
            )
          }
        />
        <PopupNotification />
      </SimpleShowLayout>
    </Show>
  );
};
