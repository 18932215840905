import React from "react";
import _ from "lodash";
import {
  useUpdate,
  useNotify,
  usePermissions,
  SelectInput,
  TextInput,
  SimpleForm
} from "react-admin";
import {
  IconButton,
  Typography,
  Link,
  TextField,
  Button,
  InputAdornment,
  Checkbox,
  Box
} from "@material-ui/core";
import { Spacer } from "./../generics/spacer";
import { amountValidator } from "../../utils/form-validators";
import { CustomModal } from "@shopthing-opn-shared/admin-dashboard";
import {
  extractAuthClaims,
  hasPermissions,
  BooleanRoles
} from "../../ts/interfaces/role-interface";

import { Specifications } from "../../ts/interfaces/order-interface";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { notifyMessage } from "../../utils/primitive";
import { required } from "ra-core";

const useStyles = makeStyles( () =>
  createStyles( {
    textfield: {
      width: "100%",
    },
    information: {
      width: "100%",
      fontSize: "0.85em",
    },
    checkbox: {
      marginTop: "-3px",
    },
    label: {
      fontSize: "1.2em",
    },
    reverseInfoBox: {
      width: "100%",
    },
    simpleFormContainer: {
      width: "700px",
    },
    customTextArea: {
      "& .MuiFilledInput-root": {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        backgroundColor: "inherit",
        borderStyle: "solid",
        borderWidth: "thin",
        borderColor: "grey",
      },
    },
  } )
);

interface IRefundButton {
  orderId: string;
  specifications: Specifications;
}

const categoryChoices = [
  { id: "category-shipping", name: "Shipping" },
  { id: "category-seller-error", name: "Seller Error" },
  { id: "category-retailer-error", name: "Retailer Error" },
  { id: "category-fraud", name: "Fraud" },
  { id: "category-vip", name: "VIP" },
  { id: "category-other", name: "Other" },
];
export const RefundButton: React.FunctionComponent<IRefundButton> = (
  properties
) =>
{
  const [open, setOpen] = React.useState( false );
  const [values, setValues] = React.useState( {
    amount: "0",
    refundCategory: "",
  } );
  const [formError, setError] = React.useState( "" );
  const [touched, setTouched] = React.useState( false );
  const [reverseTransfer, setReverseTransfer] = React.useState( true );
  const [addProcessingFee, setAddProcessingFee] = React.useState( false );
  const [note, setNote] = React.useState( "" );
  const [disableAddProcessingFeeCheckbox, setDisableAddProcessingFeeCheckbox] =
    React.useState( false );

  const refundCategoryValidator = ( value: string ): string =>
  {
    const refundCategory = categoryChoices.find( ( item ) => item.id === value );
    if ( !refundCategory )
    {
      return "Invalid refund category provided";
    }

    return "";
  };

  const validators: { [key: string]: ( value: string ) => string } = {
    amount: amountValidator,
    refundCategory: refundCategoryValidator,
  };

  const notify = useNotify();
  const [update, { loading }] = useUpdate(
    "orders-refund",
    properties.orderId,
    {
      // Convert amount to cents
      amount: Number( values.amount.replace( ".", "" ) ),
      reverseTransfer,
      addProcessingFee,
      note,
      refundCategory: categoryChoices.find(
        ( item ) => item.id === values.refundCategory
      )?.name,
    },
    {},
    {
      onSuccess: () =>
      {
        window.location.reload();
      },
      onFailure: ( error: Error ) =>
      {
        notifyMessage( error, notify );
      },
    }
  );

  const handleOpen = () =>
  {
    setOpen( true );
  };

  const handleClose = () =>
  {
    setOpen( false );
  };
  interface InputEvent {
    target: EventTarget;
  }

  interface EventTarget {
    name: string;
    value: string;
  }
  const handleInputChange = ( event: InputEvent ) =>
  {
    const { name, value } = event.target;
    const valuesData = { ...values, [name]: value };
    if ( valuesData.refundCategory && valuesData.amount !== "0" )
    {
      setTouched( true );
    }

    const error = validators[name]( value );
    setError( error );
    setValues( valuesData );
  };

  const noteInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
  {
    setNote( event.target.value );
  };

  const handleReverseTransferCheckBox = () =>
  {
    if ( !reverseTransfer === false )
    {
      setDisableAddProcessingFeeCheckbox( true );
      setAddProcessingFee( false );
    }
    else
    {
      setDisableAddProcessingFeeCheckbox( false );
    }
    setReverseTransfer( !reverseTransfer );
  };

  const classes = useStyles();
  const { loaded, permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );

  const reverseInfo =
    hasPermissions( [BooleanRoles.Admin, BooleanRoles.Seller], roleClaims ) &&
    !hasPermissions( [BooleanRoles.Partner], roleClaims ) ? (
        <div>
          <Spacer />
          <p className={classes.information}>
          When Reverse transfer is selected the Seller&rsquo;s balance will be
          debited the amount of the refund. <br />
          If Reverse transfer is not selected, ShopThing will be debited the
          amount of the refund.
          </p>
          <label className={classes.label} htmlFor="reversible">
          Reverse Transfer
          </label>
          <Checkbox
            name="reversible"
            id="reversible"
            checked={reverseTransfer}
            onChange={handleReverseTransferCheckBox}
            className={classes.checkbox}
          />
        </div>
      ) : null;

  const addProcessFeeCheckBox = _.get(
    properties,
    "specifications.isTransactionFee",
    false
  ) &&
    hasPermissions( [BooleanRoles.Admin, BooleanRoles.Seller], roleClaims ) &&
    !hasPermissions( [BooleanRoles.Partner], roleClaims ) && (
    <div>
      <label className={classes.label} htmlFor="addProcessingFee">
          Charge Processing Fee
      </label>
      <Checkbox
        name="addProcessingFee"
        id="addProcessingFee"
        checked={addProcessingFee}
        onChange={() => setAddProcessingFee( !addProcessingFee )}
        disabled={disableAddProcessingFeeCheckbox}
        className={classes.checkbox}
      />
    </div>
  );

  const body = loaded ? (
    <div>
      <SimpleForm toolbar={<></>} className={classes.simpleFormContainer}>
        <SelectInput
          source="refundCategory"
          label="Refund Category"
          alwaysOn
          allowEmpty={false}
          validate={required()}
          choices={categoryChoices}
          initialValue={values.refundCategory}
          onChange={handleInputChange}
        />
        <TextInput
          source="note"
          name="note"
          label="Notes"
          multiline={true}
          fullWidth={true}
          resettable={false}
          initialValue={note}
          onChange={noteInputChange}
          rows="5"
          className={classes.customTextArea}
        />
        <TextField
          name="amount"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          onChange={handleInputChange}
          value={values.amount}
          error={Boolean( formError )}
          helperText={formError}
          className={classes.textfield}
          type="number"
          label="Enter Amount to Refund"
        />
        <Box className={classes.reverseInfoBox}>
          {reverseInfo}
          {addProcessFeeCheckBox}
        </Box>
        <Spacer />
        <Button onClick={update} disabled={!touched || Boolean( formError )}>
          Refund
        </Button>
      </SimpleForm>
    </div>
  ) : null;

  return (
    <>
      <IconButton
        onClick={handleOpen}
        color={"primary"}
        disabled={loading}
        disableRipple
        disableFocusRipple
      >
        <Typography>
          <Link>Select Amount to Refund</Link>
        </Typography>
      </IconButton>
      <CustomModal open={open} onClose={handleClose}>
        {body}
      </CustomModal>
    </>
  );
};
