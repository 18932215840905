import * as React from "react";
import { usePermissions } from "react-admin";
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Box,
  Divider
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  OrderStatus,
  Refund,
  Totals,
  Seller,
  Specifications
} from "src/ts/interfaces/order-interface";
import { RefundButton } from "./refund-button";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  extractAuthClaims,
  hasPermissions,
  BooleanRoles
} from "../../ts/interfaces/role-interface";
import { formatPrice } from "../../utils/primitive";

const useStyles = makeStyles( () =>
  createStyles( {
    table: {
      border: 0,
    },
    column: {
      flexBasis: "49.75%",
    },
    assignment: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    accordionSummary: {
      flexDirection: "row-reverse",
    },
    tableContainer: {
      display: "flex",
      alignItems: "center",
    },
    expandButton: {
      border: "none",
      background: "none",
      outline: "none",
    },
    rotate: {
      transform: "rotate(180deg)",
    },
  } )
);
export const OrderTotalRow = ( {
  totals,
  refund,
  orderId,
  status,
  specifications,
  source,
}: {
  totals: Totals;
  refund?: Refund;
  orderId: string;
  status: OrderStatus;
  seller: Seller;
  specifications: Specifications;
  source?: string;
} ): JSX.Element =>
{
  const classes = useStyles();
  const { loaded, permissions } = usePermissions();
  const roleClaims = extractAuthClaims( permissions );
  const [expanded, setExpanded] = React.useState( false );

  const toggleExpand = () => setExpanded( ( val ) => !val );

  return (
    <>
      <Divider />
      <TableContainer className={classes.tableContainer}>
        {specifications.coupon && (
          <button
            aria-label="expand"
            className={`${classes.expandButton} ${
              expanded ? classes.rotate : ""
            }`}
            onClick={toggleExpand}
          >
            <ExpandMoreIcon />
          </button>
        )}
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow className={classes.table}>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Sub-Total</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Fees</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Shipping</Typography>
              </TableCell>
              {specifications.packageProtectionSettings && (
                <TableCell className={classes.table} align="left">
                  <Typography variant={"caption"}>
                    Package Protection
                  </Typography>
                </TableCell>
              )}
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Item Tax</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Fee Tax</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Shipping Tax</Typography>
              </TableCell>
              {specifications.coupon && (
                <TableCell
                  className={classes.table}
                  align="left"
                  onClick={toggleExpand}
                >
                  <Typography variant={"caption"}>Discount</Typography>
                </TableCell>
              )}
              {specifications.packageProtectionSettings && (
                <TableCell className={classes.table} align="left">
                  <Typography variant={"caption"}>
                    Package Protection Tax
                  </Typography>
                </TableCell>
              )}
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Total</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Refund</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.table} align="left">
                <Typography>
                  {formatPrice( totals.subtotal, specifications.currency )}
                </Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography>
                  {formatPrice( totals.fee, specifications.currency )}
                </Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography>
                  {formatPrice( totals.shipping, specifications.currency )}
                </Typography>
              </TableCell>
              {specifications.packageProtectionSettings && (
                <TableCell className={classes.table} align="left">
                  <Typography>
                    {specifications.packageProtectionSettings?.isApplied
                      ? totals.packageProtection &&
                        formatPrice(
                          totals.packageProtection,
                          specifications.currency
                        )
                      : "Declined"}
                  </Typography>
                </TableCell>
              )}
              <TableCell className={classes.table} align="left">
                <Typography>
                  {formatPrice(
                    totals.productSubtotalTax,
                    specifications.currency
                  )}
                </Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography>
                  {formatPrice(
                    ( totals.serviceTax || 0 ).toString(),
                    specifications.currency
                  )}
                </Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography>
                  {formatPrice(
                    ( totals.shippingTax || 0 ).toString(),
                    specifications.currency
                  )}
                </Typography>
              </TableCell>
              {specifications.coupon && (
                <TableCell className={classes.table} align="left">
                  <Typography>
                    -{" "}
                    {totals.totalDiscount &&
                      formatPrice(
                        totals.totalDiscount,
                        specifications.currency
                      )}
                  </Typography>
                </TableCell>
              )}
              {specifications.packageProtectionSettings && (
                <TableCell className={classes.table} align="left">
                  <Typography>
                    {specifications.packageProtectionSettings?.isApplied
                      ? formatPrice(
                        ( totals.packageProtectionTax || 0 ).toString(),
                        specifications.currency
                      )
                      : "Declined"}
                  </Typography>
                </TableCell>
              )}
              <TableCell className={classes.table} align="left">
                <Typography>
                  {" "}
                  {formatPrice( totals.totalPay, specifications.currency )}
                </Typography>
              </TableCell>
              {loaded ? (
                <TableCell className={classes.table} align="left">
                  {refund ? (
                    <Typography>{refund.amount}</Typography>
                  ) : ( ["confirmed", "pending", "fulfilled"].includes( status ) &&
                    source !== "external" &&
                    hasPermissions(
                      [BooleanRoles.RefundCustomerService, BooleanRoles.Admin],
                      roleClaims
                    ) ? (
                      <RefundButton
                        orderId={orderId}
                        specifications={specifications}
                      />
                    ) : (
                      <Typography>Not Eligible for Refund</Typography>
                    ) )}
                </TableCell>
              ) : null}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {specifications.coupon && expanded && (
        <div className={classes.assignment}>
          {" "}
          <div className={classes.column}>
            <Box bgcolor="#f7f9fa">
              {" "}
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.table} align="left">
                        <Typography variant={"h6"}>Coupon code</Typography>
                      </TableCell>
                      <TableCell className={classes.table} align="right">
                        <Typography>{specifications.coupon.code}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.table} align="left">
                        <Typography variant={"h6"}>
                          Minimum purchase value
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.table} align="right">
                        <Typography>
                          {specifications.coupon.minimumAmount
                            ? formatPrice(
                              specifications.coupon.minimumAmount,
                              specifications.currency
                            )
                            : "-"}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
          <div className={classes.column}>
            <Box bgcolor="#f7f9fa">
              {" "}
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.table} align="left">
                        <Typography variant={"h6"}>Type</Typography>
                      </TableCell>
                      <TableCell className={classes.table} align="right">
                        <Typography style={{ textTransform: "capitalize" }}>
                          {specifications.coupon.discountType}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.table} align="left">
                        <Typography variant={"h6"}>Value</Typography>
                      </TableCell>
                      <TableCell className={classes.table} align="right">
                        <Typography>
                          {formatPrice(
                            totals.totalDiscount,
                            specifications.currency
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
        </div>
      )}
      <Divider />
    </>
  );
};
