import * as React from "react";
import { App } from "./app";
import { Resource } from "react-admin";
import { dataProvider } from "./adapters/data-provider";
import { BarcodeProvider } from "./contexts/barcode-context";
import { Dashboard } from "@shopthing-opn-shared/admin-dashboard";
import { config } from "./config";
import { CustomLayout } from "./custom-layout";
import { OrderList } from "./components/order-list";
import { OrderShow } from "./components/order-show";
import { OrderEdit } from "./components/order-edit";
import { ManualOrdersCreate } from "./components/order/return-label/order-create";

import { EventList } from "./components/events/list";
import { EventEdit } from "./components/events/edit";
import { EventShow } from "./components/events/show";

import { ReconciliationList } from "./components/reconciliation/list";
import { EditorialList } from "./components/editorials/list";
import { EditorialEdit } from "./components/editorials/edit";
import { EditorialCreate } from "./components/editorials/create";

import { PickupOrderList } from "./components/pickup-orders/pickup-order-list";
import { PickupOrderShow } from "./components/pickup-orders/pickup-order-show";
import { FulfilledOrderList } from "./components/fulfilled-orders/fulfilled-order-list";

import { PotentialBundleList } from "./components/potential-bundles/potential-bundle-list";
import { BundleDetailShow } from "./components/potential-bundles/bundle-detail-show";
import { BundledDetailShow } from "./components/bundled-orders/bundled-detail-show";

import { SettingsList } from "./components/settings/list";
import { SettingsEdit } from "./components/settings/edit";

import { BundledOrderList } from "./components/bundled-orders/list";

import { ManualReviewList } from "./components/manual-review/list";
import { SellerManualReviewList } from "./components/manual-review/seller-list";

import { ClosetList } from "./components/second-closet/list";
import { ClosetCreate } from "./components/second-closet/create";

import { ReportList } from "./components/reports/list";

import {
  BooleanRoles,
  extractAuthClaims,
  hasPermissions,
  UserClaims
} from "./ts/interfaces/role-interface";
import { Route } from "react-router-dom";

import { UserList } from "./components/users/list";
import { UserEdit } from "./components/users/edit";
import { RtDashboard } from "./components/dashboard/dashboard";

import { RecommendationOverrideList } from "./components/recommendation-override/list";
import { RecommendationOverrideProductList } from "./components/recommendation-override/edit";
import { MultiEventReport } from "./components/reports/multi-event-report";

import { ProductInventoryShow } from "./components/product-inventory-items/product-inventory-show";
import { ProductInventoryItemsList } from "./components/product-inventory-items/list";
import { ProductInventoryEdit } from "./components/product-inventory-items/product-inventory-edit";
import { ProductInventoryCreate } from "./components/product-inventory-items/product-inventory-create";
import { CardList } from "./components/cards/list";
import { CollaborationEventList } from "./components/collaboration-events/list";
import { CollaborationEventCreate } from "./components/collaboration-events/create";
import { CollaborationEventEdit } from "./components/collaboration-events/edit";
import { EventProductEdit } from "./components/events/event-product-edit";
import { OrderReviewList } from "./components/order-review/list";
import { EventRank } from "./components/event-rank/list";
import { AnnouncementList } from "./components/announcements/list";
import { AnnouncementCreate } from "./components/announcements/create";
import { AnnouncementShow } from "./components/announcements/details";
import { AnnouncementEdit } from "./components/announcements/edit";
import { ChatReportsList } from "./components/chat-reports/list";
import { ChatReportsEdit } from "./components/chat-reports/edit";
import { SellerMessagesList } from "./components/seller-messages/list";
import { SellerMessagesShow } from "./components/seller-messages/show";
import { SellerMessageCreate } from "./components/seller-messages/create";
import { NotificationProvider } from "./components/custom-notification/notification";

export interface IMainProperties {
  app: App;
}

const platformDataProvider = dataProvider( config.dataProviderRoot );
export class Main extends React.Component<IMainProperties>
{
  constructor( properties: IMainProperties )
  {
    super( properties );
  }

  public render(): JSX.Element
  {
    const customRoutes = [
      <Route
        exact
        path="/dashboards/realtime-order-overview"
        component={RtDashboard}
        key={"realtime-order-overview"}
      />,
    ];
    return (
      <>
        <NotificationProvider>
          <BarcodeProvider>
            <Dashboard
              firebaseConfig={config.firebase}
              dataProvider={platformDataProvider}
              customRoutes={customRoutes}
              layout={CustomLayout}
            >
              {( permissions: UserClaims ) =>
              {
                const roleClaims = extractAuthClaims( permissions );
                return [
                  hasPermissions(
                    [
                      BooleanRoles.Seller,
                      BooleanRoles.FulfillmentReadOnly,
                      BooleanRoles.Fulfillment,
                      BooleanRoles.CustomerService,
                      BooleanRoles.RefundCustomerService,
                      BooleanRoles.Admin,
                      BooleanRoles.Partner,
                    ],
                    roleClaims
                  ) && (
                    <Resource
                      name="orders"
                      list={OrderList}
                      {...( hasPermissions( [BooleanRoles.Admin], roleClaims )
                        ? { create: ManualOrdersCreate }
                        : {} )}
                      show={OrderShow}
                      edit={
                        hasPermissions(
                          [
                            BooleanRoles.CustomerService,
                            BooleanRoles.RefundCustomerService,
                            BooleanRoles.Admin,
                          ],
                          roleClaims
                        ) && OrderEdit
                      }
                      options={{
                        label: "Orders",
                      }}
                    />
                  ),
                  hasPermissions(
                    [
                      BooleanRoles.CustomerService,
                      BooleanRoles.RefundCustomerService,
                      BooleanRoles.Admin,
                    ],
                    roleClaims
                  ) && (
                    <Resource
                      name="users"
                      list={UserList}
                      edit={
                        hasPermissions(
                          [
                            BooleanRoles.CustomerService,
                            BooleanRoles.RefundCustomerService,
                            BooleanRoles.Admin,
                          ],
                          roleClaims
                        )
                          ? UserEdit
                          : null
                      }
                      options={{
                        label: "Users",
                      }}
                    />
                  ),
                  <Resource key="buyers" name="buyers" />,
                  hasPermissions( [BooleanRoles.Admin], roleClaims ) && (
                    <Resource
                      name="reconciliation"
                      list={ReconciliationList}
                      options={{
                        label: "Reconcilliations",
                      }}
                    />
                  ),
                  hasPermissions( [BooleanRoles.Admin], roleClaims ) && (
                    <Resource
                      name="settings"
                      list={SettingsList}
                      edit={SettingsEdit}
                      options={{
                        label: "Settings",
                      }}
                    />
                  ),
                  hasPermissions( [BooleanRoles.Admin], roleClaims ) && (
                    <Resource
                      name="editorials"
                      list={EditorialList}
                      edit={EditorialEdit}
                      create={EditorialCreate}
                      options={{
                        label: "Editorials",
                      }}
                    />
                  ),
                  <Resource key="event-products" name="event-products" />,
                  hasPermissions(
                    [
                      BooleanRoles.FulfillmentReadOnly,
                      BooleanRoles.Fulfillment,
                      BooleanRoles.CustomerService,
                      BooleanRoles.RefundCustomerService,
                      BooleanRoles.Admin,
                      BooleanRoles.Partner,
                    ],
                    roleClaims
                  ) && (
                    <Resource
                      key="bundled-orders"
                      name="bundled-orders"
                      list={
                        hasPermissions(
                          [
                            BooleanRoles.Fulfillment,
                            BooleanRoles.CustomerService,
                            BooleanRoles.RefundCustomerService,
                            BooleanRoles.Admin,
                            BooleanRoles.Partner,
                          ],
                          roleClaims
                        )
                          ? BundledOrderList
                          : null
                      }
                      show={BundledDetailShow}
                      options={{
                        label: "Manifests",
                      }}
                    />
                  ),
                  <Resource key="membership" name="membership" />,
                  <Resource key="authenticatedUser" name="authenticatedUser" />,
                  <Resource key="products" name="products" />,
                  <Resource key="shipping-labels" name="shipping-labels" />,
                  <Resource
                    key="return-shipping-labels"
                    name="return-shipping-labels"
                  />,
                  <Resource
                    key="authentication-shipping-labels"
                    name="authentication-shipping-labels"
                  />,
                  <Resource
                    key="events-live-and-upcoming"
                    name="events-live-and-upcoming"
                  />,
                  <Resource key="return-reasons" name="return-reasons" />,
                  <Resource key="partnerships" name="partnerships" />,
                  <Resource key="get-tags" name="get-tags" />,
                  <Resource key="return-locations" name="return-locations" />,
                  <Resource key="categories" name="categories" />,
                  <Resource key="brands" name="brands" />,
                  <Resource key="shopper-markets" name="shopper-markets" />,
                  <Resource key="products-clone" name="products-clone" />,
                  <Resource
                    key="potential-bundles-for-order"
                    name="potential-bundles-for-order"
                  />,
                  hasPermissions(
                    [
                      BooleanRoles.Fulfillment,
                      BooleanRoles.CustomerService,
                      BooleanRoles.RefundCustomerService,
                      BooleanRoles.Admin,
                    ],
                    roleClaims
                  ) && (
                    <Resource
                      name="pickup-orders"
                      list={PickupOrderList}
                      show={PickupOrderShow}
                      options={{
                        label: "Pickup Orders",
                      }}
                    />
                  ),
                  hasPermissions(
                    [
                      BooleanRoles.FulfillmentReadOnly,
                      BooleanRoles.Fulfillment,
                      BooleanRoles.CustomerService,
                      BooleanRoles.RefundCustomerService,
                      BooleanRoles.Admin,
                    ],
                    roleClaims
                  ) && (
                    <Resource
                      name="fulfilled-orders"
                      list={FulfilledOrderList}
                      show={OrderShow}
                      options={{
                        label: "Fulfilled Orders",
                      }}
                    />
                  ),
                  hasPermissions(
                    [
                      BooleanRoles.CustomerService,
                      BooleanRoles.RefundCustomerService,
                      BooleanRoles.Seller,
                      BooleanRoles.Admin,
                    ],
                    roleClaims
                  ) && (
                    <Resource
                      name="events"
                      list={EventList}
                      show={EventShow}
                      edit={
                        hasPermissions(
                          [
                            BooleanRoles.CustomerService,
                            BooleanRoles.RefundCustomerService,
                            BooleanRoles.Admin,
                          ],
                          roleClaims
                        )
                          ? EventEdit
                          : null
                      }
                      options={{ label: "Events" }}
                    />
                  ),
                  hasPermissions(
                    [
                      BooleanRoles.CustomerService,
                      BooleanRoles.RefundCustomerService,
                      BooleanRoles.Seller,
                      BooleanRoles.Admin,
                    ],
                    roleClaims
                  ) && (
                    <Resource
                      name="event-product-edit"
                      edit={
                        hasPermissions(
                          [
                            BooleanRoles.CustomerService,
                            BooleanRoles.RefundCustomerService,
                            BooleanRoles.Admin,
                            BooleanRoles.Seller,
                          ],
                          roleClaims
                        )
                          ? EventProductEdit
                          : null
                      }
                      options={{ label: "Product" }}
                    />
                  ),
                  hasPermissions( [BooleanRoles.Admin], roleClaims ) && (
                    <Resource
                      name="collaboration-events"
                      list={CollaborationEventList}
                      edit={CollaborationEventEdit}
                      create={CollaborationEventCreate}
                      options={{
                        label: "Collaboration Events",
                      }}
                    />
                  ),
                  <Resource
                    key="collaboration-event-events"
                    name="collaboration-event-events"
                  />,
                  hasPermissions(
                    [
                      BooleanRoles.CustomerService,
                      BooleanRoles.RefundCustomerService,
                      BooleanRoles.Seller,
                      BooleanRoles.Admin,
                    ],
                    roleClaims
                  ) && (
                    <Resource name="coupons" options={{ label: "Coupons" }} />
                  ),
                  hasPermissions(
                    [
                      BooleanRoles.FulfillmentReadOnly,
                      BooleanRoles.Fulfillment,
                      BooleanRoles.CustomerService,
                      BooleanRoles.RefundCustomerService,
                      BooleanRoles.Admin,
                      BooleanRoles.Partner,
                      BooleanRoles.Seller,
                    ],
                    roleClaims
                  ) && (
                    <Resource
                      name="potential-bundles"
                      list={PotentialBundleList}
                      show={BundleDetailShow}
                      options={{
                        label: "Potential Bundles",
                      }}
                    />
                  ),
                  hasPermissions(
                    [
                      BooleanRoles.RefundCustomerService,
                      BooleanRoles.Admin,
                      BooleanRoles.Seller,
                      BooleanRoles.Partner,
                    ],
                    roleClaims
                  ) && (
                    <Resource
                      name="manual-review"
                      list={
                        !hasPermissions(
                          [
                            BooleanRoles.FulfillmentReadOnly,
                            BooleanRoles.Fulfillment,
                            BooleanRoles.CustomerService,
                            BooleanRoles.RefundCustomerService,
                            BooleanRoles.Admin,
                          ],
                          roleClaims
                        ) &&
                        hasPermissions(
                          [BooleanRoles.Seller, BooleanRoles.Partner],
                          roleClaims
                        )
                          ? SellerManualReviewList
                          : ManualReviewList
                      }
                      options={{
                        label: "Manual Review",
                      }}
                    />
                  ),
                  <Resource
                    key="order-review"
                    name="order-review"
                    {...( hasPermissions( [BooleanRoles.Admin], roleClaims )
                      ? { list: OrderReviewList }
                      : {} )}
                    options={{
                      label: "Manage Reviews",
                    }}
                  />,
                  <Resource
                    key="chat-reports"
                    name="chat-reports"
                    {...( hasPermissions( [BooleanRoles.Admin], roleClaims )
                      ? {
                        list: ChatReportsList,
                        edit: ChatReportsEdit,
                      }
                      : {} )}
                    options={{
                      label: "Reported Chats",
                    }}
                  />,
                  hasPermissions( [BooleanRoles.Admin], roleClaims ) && (
                    <Resource
                      name="second-closet"
                      list={ClosetList}
                      create={ClosetCreate}
                      options={{
                        label: "Second Closet",
                      }}
                    />
                  ),
                  hasPermissions( [BooleanRoles.Admin], roleClaims ) && (
                    <Resource
                      key="reports"
                      name="reports"
                      list={ReportList}
                      create={{}}
                      options={{
                        label: "Reports",
                      }}
                    />
                  ),
                  hasPermissions( [BooleanRoles.Admin], roleClaims ) && (
                    <Resource
                      name="multi-event-reports"
                      list={MultiEventReport}
                      options={{
                        label: `Multi Event Report`,
                      }}
                    />
                  ),
                  hasPermissions( [BooleanRoles.Admin], roleClaims ) && (
                    <Resource key="isAdminUser" name="isAdminUser" />
                  ),
                  hasPermissions( [BooleanRoles.Admin], roleClaims ) && (
                    <Resource
                      name="recommendation-override"
                      list={RecommendationOverrideList}
                      options={{
                        label: `Recommendation System Override`,
                      }}
                    />
                  ),
                  hasPermissions( [BooleanRoles.Admin], roleClaims ) && (
                    <Resource
                      name="recommendation-override-products"
                      edit={RecommendationOverrideProductList}
                      options={{
                        label: `Recommendation System Override Products`,
                      }}
                    />
                  ),

                  hasPermissions( [BooleanRoles.Admin], roleClaims ) && (
                    <Resource
                      name="product-inventory-items"
                      list={ProductInventoryItemsList}
                      show={ProductInventoryShow}
                      edit={ProductInventoryEdit}
                      create={ProductInventoryCreate}
                      options={{
                        label: `Product Inventory`,
                      }}
                    />
                  ),
                  hasPermissions( [BooleanRoles.Seller], roleClaims ) && (
                    <Resource
                      name="cards"
                      list={CardList}
                      options={{ label: "Saved Payments" }}
                    />
                  ),
                  hasPermissions( [BooleanRoles.Admin], roleClaims ) && (
                    <Resource
                      name="events-rank"
                      list={EventRank}
                      options={{ label: "Pinned Event Ranks" }}
                    />
                  ),
                  hasPermissions( [BooleanRoles.Admin], roleClaims ) && (
                    <Resource
                      name="announcements"
                      list={AnnouncementList}
                      create={AnnouncementCreate}
                      show={AnnouncementShow}
                      edit={AnnouncementEdit}
                      options={{
                        label: "Video Announcements",
                      }}
                    />
                  ),
                  hasPermissions( [BooleanRoles.Admin], roleClaims ) && (
                    <Resource
                      name="seller-messages"
                      list={SellerMessagesList}
                      show={SellerMessagesShow}
                      create={SellerMessageCreate}
                      options={{
                        label: "ShopThing Concierge",
                      }}
                    />
                  ),
                ];
              }}
            </Dashboard>
          </BarcodeProvider>
        </NotificationProvider>
      </>
    );
  }
}
