import _ from "lodash";
import * as React from "react";
import {
  Tab,
  TabbedShowLayout,
  useNotify,
  usePermissions,
  useUpdate,
  useDataProvider
} from "react-admin";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";

import {
  CustomModal,
  DymoProvider
} from "@shopthing-opn-shared/admin-dashboard";

import {
  DisputeNote,
  Fulfillments,
  Item,
  Order,
  Review,
  AuthenticationStatus
} from "../../ts/interfaces/order-interface";
import {
  BooleanRoles,
  hasPermissions
} from "../../ts/interfaces/role-interface";

import { ItemTable } from "./item-table";
import { FulfillmentTable } from "./fulfillment-table";
import { OrderTotalRow } from "./order-totals-row";
import { barcodeTemplate } from "../../templates/barcode-template";
import { ShippingCarriers } from "../../ts/interfaces/shared";
import { Spacer } from "../generics/spacer";
import { TrueFalseIcon } from "../generics/true-false-icon";
import { ButtonStyles } from "../generics/button-styles";
import { ConvertPickupForm } from "../pickup-orders/convert-pickup-form";
import { ActionButton } from "../generics/buttons";
import { getNumOfDaysToFulfill } from "./days-to-fulfill-field";
import { Notes } from "./notes";
import { ResolveDisputeButton } from "./resolve-dispute-button";
import { ReturnHistoryTable } from "../return-history-table";
import { DeliveryHistoryTable } from "./delivery-history-table";
import { required, useGetOne } from "ra-core";
import { useBarcode } from "../../contexts/barcode-context";
import { PopupNotificationType } from "../popup-notification/popup-notification";
import { LaunchDarklyProvider } from "../../contexts/launch-darkly-context";
import { ReturnShippingLabel } from "./return-label/return-shipping-label";
import {
  AuthenticationShippingLabel,
  authenticationShippingDirection
} from "./authentication-label/authentication-shipping-label";
import { ProductAuthenticationAction } from "../product-authentication/product-authentication-action";
import { formatPriceWithoutCurrency } from "../../utils/primitive";
import { RefundDetailsTable } from "./refund-details-table";
import { SelectInput, SimpleForm, TextInput } from "ra-ui-materialui";
import { InTextError } from "../errors/in-text-error";
import { FailedTransfer } from "./failed-transfer-table";
import { ResolveFailedTransferButton } from "./resolve-failed-transfer-button";

interface DetailProperties {
  record?: Order;
  [x: string]: unknown;
}

const useStyles = makeStyles( ( theme: Theme ) =>
  createStyles( {
    fullWidth: {
      width: "100%",
    },
    formField: {
      minWidth: "200px",
      margin: theme.spacing( 1, 2, 1, 0 ),
    },
    selectRow: {
      display: "flex",
      marginTop: "20px",
    },
    table: {
      border: 0,
    },
    button: {
      marginLeft: "5px",
      border: "1px solid black",
    },
    reviewRow: {
      fontSize: "1rem",
    },
    divider: {
      margin: theme.spacing( 4, 0 ),
    },
    inputWidth: {
      width: "80%",
    },
    buttonPadding: {
      paddingTop: "15px",
    },
  } )
);

const caCarriers = [
  { id: "cp", name: "Canada Post" },
  { id: "fedex", name: "FedEx" },
  { id: "pu", name: "Purolator" },
  { id: "ups", name: "UPS" },
  { id: "dhlexpress", name: "DHL Express" },
  { id: "dhlecommerce", name: "DHL eCommerce" },
  { id: "australia_post", name: "Australia Post" },
];

const usCarriers = [
  { id: "usps", name: "USPS" },
  { id: "fedex", name: "FedEx" },
  { id: "ups", name: "UPS" },
  { id: "dhlexpress", name: "DHL Express" },
  { id: "dhlecommerce", name: "DHL eCommerce" },
  { id: "australia_post", name: "Australia Post" },
];

const FulfillAsSellerOrPartner = ( {
  orderId,
  items,
}: {
  orderId: string;
  items: Item[];
} ) =>
{
  const productCountry = items[0].sourceCountry.toLowerCase();
  const classes = useStyles();

  const [carrier, setCarrier] = React.useState( "" );
  const [trackingNumber, setTrackingNumber] = React.useState( "" );
  const [submitted, setSubmitted] = React.useState( false );

  const { setNotification } = useBarcode();

  const [update] = useUpdate(
    "orders-fulfill",
    orderId,
    {
      orderId,
      // Added the default notes.
      customerNotes: "Enjoy your purchase!",
      shipping: {
        carrier,
        trackingNumber,
      },
    },
    {},
    {
      onSuccess: () =>
      {
        window.location.reload();
      },
      onFailure: ( error: Error ) =>
      {
        setNotification( {
          message: error.message,
          type: PopupNotificationType.error,
        } );
      },
    }
  );

  const fulfillOrder = () =>
  {
    setSubmitted( true );
    update();
  };

  const carrierChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
  {
    setCarrier( event.target.value );
  };

  const trackingNumberChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
  {
    setTrackingNumber( event.target.value );
  };

  return (
    <>
      <Spacer key="total-spacer-1" />
      <Typography variant={"h5"} key="total-spacer-2">
        Fulfill Order
      </Typography>
      <Spacer key="total-spacer-3" />
      <SimpleForm toolbar={<></>}>
        <Grid container>
          <Grid item xs={4}>
            <SelectInput
              source="carrier"
              label="Carrier"
              alwaysOn
              allowEmpty={false}
              validate={required()}
              choices={productCountry === "ca" ? caCarriers : usCarriers}
              initialValue={""}
              onChange={carrierChange}
              className={classes.inputWidth}
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              source="trackingNumber"
              onChange={trackingNumberChange}
              validate={required()}
              label="Tracking Number"
              className={classes.inputWidth}
            />
          </Grid>
          <Grid item xs={4} className={classes.buttonPadding}>
            <Button
              onClick={fulfillOrder}
              disabled={submitted || !carrier || !trackingNumber}
            >
              Fulfill Order
            </Button>
          </Grid>
        </Grid>
      </SimpleForm>
    </>
  );
};

const FulfillmentDetails = ( { order }: { order: Order } ) =>
{
  const classes = useStyles();
  return (
    <>
      <Spacer key="total-spacer-1" />
      <Typography variant={"h5"} key="total-spacer-2">
        Fulfillment Details
      </Typography>
      <Spacer key="total-spacer-3" />
      <Divider />
      <TableContainer>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow className={classes.table}>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Carrier</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Tracking Number</Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography variant={"caption"}>Customer Notes</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.table} align="left">
                <Typography>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ShippingCarriers[order.logistics.fulfillment?.carrier]
                      ?.name || order.logistics.fulfillment?.carrier
                  }
                </Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography>
                  {order.logistics.fulfillment?.trackingNumber}
                </Typography>
              </TableCell>
              <TableCell className={classes.table} align="left">
                <Typography>
                  {order.logistics.fulfillment?.customerNotes}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
    </>
  );
};

const DisputeNote = ( { note }: { note: DisputeNote } ) => (
  <>
    <Spacer />
    <Typography variant="h6">Resolved Note</Typography>
    <Spacer />
    <TableContainer>
      <Table aria-label="note-table">
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <Typography align="left" noWrap={true}>
                Created On
              </Typography>
            </TableCell>
            <TableCell align="left">
              <Typography align="left">Author</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography>Note</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow key={"note"}>
            <TableCell width={250} align="left">
              <Typography align="left" noWrap={true}>
                {note.timestamps.created
                  ? new Date( note.timestamps.created ).toLocaleString( "en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  } )
                  : ""}
                <br />
                {note.timestamps.created
                  ? new Date( note.timestamps.created ).toLocaleString( "en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                  } )
                  : ""}
              </Typography>
            </TableCell>
            <TableCell width={250} align="left">
              <Typography align="left" noWrap={true}>
                {note.author}
              </Typography>
            </TableCell>
            <TableCell align="left">{note.note}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </>
);

function DateField( props: { date: Date; label: string; className?: string } )
{
  return (
    <Typography variant={"h6"} {...props}>{`${props.label}${new Date(
      props.date
    ).toLocaleString( "en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    } )}`}</Typography>
  );
}

function OrderCreatedPlacedDateField( props: { record: Order } )
{
  if ( props.record.timestamps.paid && props.record.status !== "error" )
  {
    return (
      <DateField date={props.record.timestamps.paid} label="Date Placed: " />
    );
  }
  return (
    <DateField date={props.record.timestamps.created} label="Date Created: " />
  );
}

export const OrderDetail: React.FunctionComponent<DetailProperties> = (
  detailProperties: DetailProperties
) =>
{
  const { permissions } = usePermissions();
  const { record } = detailProperties;
  const buttonClasses = ButtonStyles();
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const [isUpdating, setIsUpdating] = React.useState( false );
  const [approveCustomerUpdate] = useUpdate(
    "manual-review",
    record.customer.id,
    {},
    {},
    {
      onSuccess: () =>
      {
        notify( "Customer Approved!" );
        window.location.href = `/#/manual-review`;
      },
      onFailure: ( error: Error ) =>
      {
        notify(
          `An error occurred, please try again later ${error.message}`,
          "warning"
        );
        setIsUpdating( false );
      },
    }
  );

  const approveCustomer = () =>
  {
    setIsUpdating( true );
    approveCustomerUpdate();
  };

  const numOfDaysToFulfill = getNumOfDaysToFulfill(
    record.timestamps.confirmed,
    record.timestamps.fulfilled,
    ["confirmed", "pending"].includes( record.status )
  );

  const shippingAddress = record?.logistics?.details?.address;
  const payment = record?.customer?.billing?.payment;
  const billingAddress = record?.customer?.billing?.address;
  const hasInitiatedReturns = record.fulfillmentIds?.some(
    ( fulfillmentId ) => record.fulfillments[fulfillmentId]?.returnStatus
  );
  const authentication = record.authentication;

  const [review, setReview] = React.useState<Review | null>( null );

  const { loading: reviewDataLoading, refetch } = useGetOne(
    "order-review",
    record.id,
    {
      onSuccess: ( response: { data: Review } ) =>
      {
        setReview( response.data );
      },
      onFailure: () =>
      {
        console.log( "No review for this order" );
      },
    }
  );

  const [approveReview, { loading: approveLoading }] = useUpdate(
    "order-review",
    review?.id || "",
    {
      status: "approved",
    },
    {},
    {
      onSuccess: () =>
      {
        notify( "Review Approved!" );
        refetch();
      },
      onFailure: ( error: Error ) =>
      {
        notify(
          `An error occurred, please try again later ${error.message}`,
          "warning"
        );
        setIsUpdating( false );
      },
    }
  );

  const [rejectReview, { loading: rejectLoading }] = useUpdate(
    "order-review",
    review?.id || "",
    {
      status: "rejected",
    },
    {},
    {
      onSuccess: () =>
      {
        notify( "Review Rejected!" );
        refetch();
      },
      onFailure: ( error: Error ) =>
      {
        notify(
          `An error occurred, please try again later ${error.message}`,
          "warning"
        );
        setIsUpdating( false );
      },
    }
  );

  const fetchSignedUrl = ( path: string ) =>
  {
    dataProvider
      .getSignedUrl( "signedUrl", { path } )
      .then( ( { data }: { data: { url: string } } ) =>
      {
        window.open( data.url );
      } );
  };

  // Handle the state of generating return label on fulfillment-table
  const [generateReturnLabel, setGenerateReturnLabel] = React.useState( {
    fulfillmentId: "",
    show: false,
  } );

  const [
    generateAuthenticationShippingLabel,
    setGenerateAuthenticationShippingLabel,
  ] = React.useState( {
    show: false,
    direction: "",
  } );

  const hasCustomerDataAccess = hasPermissions(
    [
      BooleanRoles.CustomerService,
      BooleanRoles.RefundCustomerService,
      BooleanRoles.Admin,
      BooleanRoles.Fulfillment,
      BooleanRoles.FulfillmentReadOnly,
    ],
    permissions
  );

  const {
    preventSellerOrPartnerFulfill,
    messageOnPreventSellerOrPartnerFulfill,
  } = ( () =>
  {
    const { permissions } = usePermissions();
    const latestDisputeStatus = _.get( record, "latestDisputeStatus" );
    const passedFulfillmentReview = _.get(
      record,
      "customer.passedFulfillmentReview",
      false
    );
    const disputeResolved = _.get( record, "disputeResolved" );
    const billingAndShippingMatch = record.billingAndShippingMatch;
    const prevent =
      hasPermissions(
        [BooleanRoles.Seller, BooleanRoles.Partner],
        permissions
      ) &&
      !hasPermissions( [BooleanRoles.Admin], permissions ) &&
      ["confirmed", "pending"].includes( record.status ) &&
      ( ( latestDisputeStatus &&
        ( latestDisputeStatus !== "won" || disputeResolved !== true ) ) ||
        ( !billingAndShippingMatch && !passedFulfillmentReview ) );

    let message =
      "This order cannot be shipped. Please check back again soon on the status of this order.";
    if ( !billingAndShippingMatch && !passedFulfillmentReview )
    {
      message =
        "This order requires additional information from the customer and cannot be shipped. Please check back again soon on the status of this order.";
    }
    else if (
      latestDisputeStatus &&
      ( latestDisputeStatus !== "won" || disputeResolved !== true )
    )
    {
      message =
        latestDisputeStatus === "fraudulent"
          ? "This order has been flagged as fraudulent, and cannot be shipped. This order will need to be cancelled, and a refund will be processed."
          : "This order has been disputed by the customer, and as such it cannot be shipped. Please check back again soon on the status of this order.";
    }

    return {
      preventSellerOrPartnerFulfill: prevent,
      messageOnPreventSellerOrPartnerFulfill: message,
    };
  } )();

  return (
    <LaunchDarklyProvider>
      <DymoProvider
        barcodeTemplate={barcodeTemplate}
        scriptPath={"/scripts/dymo.js"}
      >
        <Card>
          <CardContent>
            <CustomModal open={isUpdating} onClose={() => null}>
              <h2>Granting Approval to Customer</h2>
              <div>
                We are currently granting approval for this customer and all of
                their orders. This may take a while. Please do not close this
                page.
              </div>
              <div>
                After this is complete you will be redirected to back to the
                manual order review queue.
              </div>
            </CustomModal>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={8}>
                <Typography variant="h5" gutterBottom>
                  {`Order ${record.id}`}
                </Typography>
                <Box
                  border={2}
                  bgcolor={"#f7f9fa"}
                  css={{ borderColor: "#C5D1D9" }}
                  padding={2}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography variant={"h6"}>{`Status: ${
                        record.latestDisputeStatus === "lost" &&
                        record.disputeResolved === true
                          ? "Dispute Lost"
                          : record.status
                      }`}</Typography>
                      <Typography variant={"h6"}>
                        {`Shopper: `}
                        {hasPermissions(
                          [
                            BooleanRoles.CustomerService,
                            BooleanRoles.RefundCustomerService,
                            BooleanRoles.Admin,
                          ],
                          permissions
                        ) ? (
                            <Link href={`/#/users/${record.seller.id}`}>
                              {`@${record.seller.handle}`}
                            </Link>
                          ) : (
                            `@${record.seller.handle}`
                          )}
                      </Typography>
                      {record.sellerType && (
                        <Typography
                          variant={"h6"}
                        >{`Shopper Type: ${record.sellerType}`}</Typography>
                      )}
                      <Typography
                        variant={"h6"}
                      >{`Fulfilled By: ${record.fulfilledBy}`}</Typography>
                      <OrderCreatedPlacedDateField record={record} />
                      {record.timestamps.confirmed && (
                        <DateField
                          date={record.timestamps.confirmed}
                          label="Date Confirmed: "
                        />
                      )}
                      {record.status === "fulfilled" && (
                        <DateField
                          date={record.timestamps.fulfilled}
                          label="Date Fulfilled: "
                        />
                      )}
                      {record.timestamps.confirmed && (
                        <Typography variant={"h6"}>{`Days to Fulfill ${
                          !record.timestamps.fulfilled &&
                          !["fulfilled", "refunded", "cancelled"].includes(
                            record.status
                          )
                            ? "(In Progress)"
                            : "(Complete)"
                        }: ${
                          numOfDaysToFulfill || numOfDaysToFulfill === 0
                            ? numOfDaysToFulfill
                            : "-"
                        }`}</Typography>
                      )}
                      <Typography variant={"h6"}>
                        Event:{" "}
                        <Link href={`/#/events/${record.seller.event}/show`}>
                          {`${record.seller.event}`}
                        </Link>
                      </Typography>
                      <Typography variant={"h6"}>
                        {`Billing and Shipping Addresses Match: `}
                        <TrueFalseIcon value={record.billingAndShippingMatch} />
                      </Typography>
                      {record.latestDisputeStatus && (
                        <Typography variant={"h6"}>
                          {`Dispute: ${record.latestDisputeStatus}`}
                        </Typography>
                      )}
                      <Typography variant={"h6"}>
                        {`Type: ${record.logistics.type}`}
                      </Typography>
                      <Typography variant={"h6"}>
                        {`Shipping Method: ${record.logistics.option}`}
                      </Typography>
                      {record.source &&
                        record.source.toLowerCase() === "microsoft" && (
                        <Typography
                          variant={"h6"}
                        >{`Source: MSFT`}</Typography>
                      )}
                      {typeof record.customer.vip === "boolean" && (
                        <Typography variant={"h6"}>
                          {`Customer status at order placement: ${
                            record.customer.vip === true
                              ? "Member"
                              : "Non-Member"
                          }`}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant="h5" gutterBottom>
                  {`Customer`}
                </Typography>
                <Box
                  border={2}
                  bgcolor={"#f7f9fa"}
                  css={{ borderColor: "#C5D1D9" }}
                  padding={2}
                  marginLeft={1}
                >
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        variant={"h6"}
                      >{`Name: ${record.customer.firstname} ${record.customer.lastname}`}</Typography>
                      <>
                        {hasCustomerDataAccess && (
                          <Typography
                            variant={"h6"}
                          >{`Phone: ${record.customer.phone}`}</Typography>
                        )}
                        {( hasCustomerDataAccess ||
                          hasPermissions(
                            [BooleanRoles.Partner],
                            permissions
                          ) ) &&
                          record.customer.email && (
                          <Typography
                            variant={"h6"}
                          >{`Email: ${record.customer.email}`}</Typography>
                        )}
                        {hasCustomerDataAccess && (
                          <Typography variant={"h6"}>
                            {`Customer Id: `}
                            {hasPermissions(
                              [
                                BooleanRoles.CustomerService,
                                BooleanRoles.RefundCustomerService,
                                BooleanRoles.Admin,
                              ],
                              permissions
                            ) ? (
                                <Link href={`/#/users/${record.customer.id}`}>
                                  {`${record.customer.id}`}
                                </Link>
                              ) : (
                                `${record.customer.id}`
                              )}
                          </Typography>
                        )}
                      </>
                      <Typography variant={"h6"}>
                        {`Passed Fulfillment Review: `}
                        <TrueFalseIcon
                          value={record.customer.passedFulfillmentReview}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {hasPermissions(
                  [BooleanRoles.Admin, BooleanRoles.RefundCustomerService],
                  permissions
                ) &&
                  ["confirmed", "pending"].includes( record.status ) &&
                  !record.customer.passedFulfillmentReview && (
                  <Box paddingTop={2} marginLeft={1} textAlign="right">
                    <Button
                      className={buttonClasses.saveButton}
                      disabled={isUpdating}
                      onClick={approveCustomer}
                    >
                        Approve Customer
                    </Button>
                  </Box>
                )}
                {hasPermissions(
                  [
                    BooleanRoles.CustomerService,
                    BooleanRoles.RefundCustomerService,
                    BooleanRoles.Fulfillment,
                    BooleanRoles.Admin,
                  ],
                  permissions
                ) &&
                  record.status === "confirmed" &&
                  record.authentication?.status === "pending" &&
                  ( record.billingAndShippingMatch ||
                    record.customer.passedFulfillmentReview ) && (
                  <Box paddingTop={2} marginLeft={1} textAlign="right">
                    <ProductAuthenticationAction
                      id={record.id}
                      authentication={record.authentication}
                      action={"authenticated"}
                      {...detailProperties}
                    />
                  </Box>
                )}
                {hasPermissions(
                  [
                    BooleanRoles.CustomerService,
                    BooleanRoles.RefundCustomerService,
                    BooleanRoles.Fulfillment,
                    BooleanRoles.Admin,
                  ],
                  permissions
                ) &&
                  record.status === "confirmed" &&
                  record.authentication?.status === "pending" &&
                  ( record.billingAndShippingMatch ||
                    record.customer.passedFulfillmentReview ) && (
                  <Box paddingTop={2} marginLeft={1} textAlign="right">
                    <ProductAuthenticationAction
                      id={record.id}
                      authentication={record.authentication}
                      action={"rejected"}
                      {...detailProperties}
                    />
                  </Box>
                )}
                {hasPermissions( [BooleanRoles.Admin], permissions ) &&
                  ["lost", "won", "fraudulent"].includes(
                    record.latestDisputeStatus
                  ) &&
                  !record.disputeResolved && (
                  <Box paddingTop={2} marginLeft={1} textAlign="right">
                    <ResolveDisputeButton
                      orderId={record.id}
                      latestDisputeStatus={record.latestDisputeStatus}
                    />
                  </Box>
                )}
                {hasPermissions( [BooleanRoles.Admin], permissions ) &&
                  record.hasTransferFailure && (
                  <Box paddingTop={2} marginLeft={1} textAlign="right">
                    <ResolveFailedTransferButton orderId={record.id} />
                  </Box>
                )}
              </Grid>
            </Grid>
            <Spacer />
            {preventSellerOrPartnerFulfill && (
              <>
                <InTextError message={messageOnPreventSellerOrPartnerFulfill} />
                <Spacer />
              </>
            )}
            <Divider />
            {generateReturnLabel.show &&
              !generateAuthenticationShippingLabel.show && (
              <>
                <Spacer />
                <ReturnShippingLabel
                  orderData={record}
                  fulfillmentId={generateReturnLabel.fulfillmentId}
                />
              </>
            )}
            {generateAuthenticationShippingLabel.show &&
              ( generateAuthenticationShippingLabel.direction ===
                authenticationShippingDirection.sellerToShopthing ||
                generateAuthenticationShippingLabel.direction ===
                  authenticationShippingDirection.shopthingToSeller ) &&
              !generateReturnLabel.show && (
              <>
                <Spacer />
                <AuthenticationShippingLabel
                  orderData={record}
                  direction={generateAuthenticationShippingLabel.direction}
                />
              </>
            )}
            {!generateReturnLabel.show &&
              !generateAuthenticationShippingLabel.show && (
              <>
                <TabbedShowLayout>
                  <Tab label="Fulfillment">
                    <Spacer />
                    <Typography variant={"h5"}>Items</Typography>
                    <Spacer />
                    {Object.values( record.cart.items ).map(
                      ( item: Item, itemIndex ) =>
                        new Array( item.quantity ).fill( 0 ).map( () => (
                          <>
                            <ItemTable
                              item={item}
                              key={`${item.skuId}-${itemIndex}`}
                              orderId={record.id}
                              orderStatus={record.status}
                              authentication={record.authentication}
                              latestDisputeStatus={record.latestDisputeStatus}
                              {...detailProperties}
                            />
                            <Spacer />
                          </>
                        ) )
                    )}
                    {record.fulfillments && [
                      <Spacer key={0} />,
                      <Typography variant={"h5"} key={1}>
                          Fulfillments
                      </Typography>,
                      <Spacer key={2} />,
                    ]}
                    {record.fulfillments &&
                        Object.values( record.fulfillments ).map(
                          ( fulfillment: Fulfillments, fulfillmentIndex ) => (
                            <React.Fragment
                              key={`${record.id}-${fulfillment.externalId}-${fulfillmentIndex}`}
                            >
                              <FulfillmentTable
                                fulfillment={fulfillment}
                                orderId={record.id}
                                orderStatus={record.status}
                                latestDisputeStatus={record.latestDisputeStatus}
                                disputeResolved={record.disputeResolved}
                                item={record.cart.items[0]}
                                fulfilledBy={record.fulfilledBy}
                                setGenerateReturnLabel={setGenerateReturnLabel}
                              />
                              <Spacer />
                            </React.Fragment>
                          )
                        )}
                    {detailProperties.record.totals && [
                      <Spacer key="total-spacer-1" />,
                      <Typography variant={"h5"} key="total-spacer-2">
                          Totals & Refund
                      </Typography>,
                      <Spacer key="total-spacer-3" />,
                      <OrderTotalRow
                        key="order-totals"
                        totals={detailProperties.record.totals}
                        orderId={detailProperties.record.id}
                        status={detailProperties.record.status}
                        refund={detailProperties.record.refund}
                        seller={detailProperties.record.seller}
                        specifications={
                          detailProperties.record.specifications
                        }
                        source={detailProperties.record.source}
                      />,
                    ]}
                    <Spacer />
                    {["confirmed", "pending"].includes( record.status ) &&
                        record.allowSellerToSelfFulfill &&
                        ( !authentication?.status ||
                          authentication.status === "authenticated" ) &&
                        ( !record.latestDisputeStatus ||
                          ( record.disputeResolved &&
                            record.latestDisputeStatus === "won" ) ) && (
                      <FulfillAsSellerOrPartner
                        orderId={record.id}
                        items={record.cart.items}
                      />
                    )}
                    {detailProperties.record.status === "fulfilled" && (
                      <FulfillmentDetails order={detailProperties.record} />
                    )}
                    <Spacer />
                    <Spacer key="total-spacer-1" />
                    <Typography variant={"h5"} key="total-spacer-2">
                        Shipping Label
                    </Typography>
                    <Divider className={classes.divider} />
                    {hasPermissions(
                      [
                        BooleanRoles.Admin,
                        BooleanRoles.RefundCustomerService,
                        BooleanRoles.Fulfillment,
                        BooleanRoles.CustomerService,
                      ],
                      permissions
                    ) &&
                        ( record.status === "confirmed" ||
                          record.status === "pending" ) &&
                        record.logistics.type === "pickup" && (
                      <ConvertPickupForm id={record.id} />
                    )}
                    {record?.status === "fulfilled" &&
                        record.logistics?.fulfillment?.labelProvider
                          ?.labelUrl && (
                      <ActionButton
                        label="View Shipping Label"
                        onClick={() =>
                        {
                          const { labelUrl } =
                                record.logistics.fulfillment.labelProvider;
                          if ( !labelUrl.startsWith( "http" ) )
                          {
                            fetchSignedUrl( labelUrl );
                          }
                          else
                          {
                            window.open( labelUrl );
                          }
                        }}
                      />
                    )}
                    {hasPermissions(
                      [
                        BooleanRoles.Admin,
                        BooleanRoles.RefundCustomerService,
                        BooleanRoles.Fulfillment,
                        BooleanRoles.CustomerService,
                      ],
                      permissions
                    ) &&
                        record?.fulfilledBy === "Seller" &&
                        record?.status === "confirmed" &&
                        record?.authentication?.status ===
                          AuthenticationStatus.PENDING &&
                        _.isEmpty(
                          _.get(
                            record,
                            "authentication.authenticationShippingLabelSellerToShopthing"
                          )
                        ) && (
                      <ActionButton
                        label="Generate Authentication Label"
                        onClick={() =>
                        {
                          setGenerateAuthenticationShippingLabel( {
                            show: true,
                            direction:
                                  authenticationShippingDirection.sellerToShopthing,
                          } );
                        }}
                      />
                    )}
                    {hasPermissions(
                      [
                        BooleanRoles.Admin,
                        BooleanRoles.RefundCustomerService,
                        BooleanRoles.Fulfillment,
                        BooleanRoles.CustomerService,
                      ],
                      permissions
                    ) &&
                        record?.fulfilledBy === "Seller" &&
                        record?.status === "confirmed" &&
                        !_.isEmpty(
                          _.get(
                            record,
                            "authentication.authenticationShippingLabelSellerToShopthing"
                          )
                        ) &&
                        !_.isNil(
                          _.get(
                            record,
                            "authentication.authenticationShippingLabelSellerToShopthing.labelProvider.labelUrl"
                          )
                        ) && (
                      <ActionButton
                        label="View Authentication Label"
                        onClick={() =>
                        {
                          const labelUrl = _.get(
                            record,
                            "authentication.authenticationShippingLabelSellerToShopthing.labelProvider.labelUrl"
                          );
                          if ( !labelUrl.startsWith( "http" ) )
                          {
                            fetchSignedUrl( labelUrl );
                          }
                          else
                          {
                            window.open( labelUrl );
                          }
                        }}
                      />
                    )}
                    {hasPermissions(
                      [
                        BooleanRoles.Admin,
                        BooleanRoles.RefundCustomerService,
                        BooleanRoles.Fulfillment,
                        BooleanRoles.CustomerService,
                      ],
                      permissions
                    ) &&
                        record?.fulfilledBy === "Seller" &&
                        record?.status === "confirmed" &&
                        record?.authentication?.status ===
                          AuthenticationStatus.REJECTED &&
                        _.isEmpty(
                          _.get(
                            record,
                            "authentication.authenticationShippingLabelShopthingToSeller"
                          )
                        ) && (
                      <ActionButton
                        label="Generate Authentication Return Label"
                        onClick={() =>
                        {
                          setGenerateAuthenticationShippingLabel( {
                            show: true,
                            direction:
                                  authenticationShippingDirection.shopthingToSeller,
                          } );
                        }}
                      />
                    )}
                    {hasPermissions(
                      [
                        BooleanRoles.Admin,
                        BooleanRoles.RefundCustomerService,
                        BooleanRoles.Fulfillment,
                        BooleanRoles.CustomerService,
                      ],
                      permissions
                    ) &&
                        record?.fulfilledBy === "Seller" &&
                        record?.status === "confirmed" &&
                        !_.isEmpty(
                          _.get(
                            record,
                            "authentication.authenticationShippingLabelShopthingToSeller"
                          )
                        ) &&
                        !_.isNil(
                          _.get(
                            record,
                            "authentication.authenticationShippingLabelShopthingToSeller.labelProvider.labelUrl"
                          )
                        ) && (
                      <ActionButton
                        label="View Authentication Return Label"
                        onClick={() =>
                        {
                          const labelUrl = _.get(
                            record,
                            "authentication.authenticationShippingLabelShopthingToSeller.labelProvider.labelUrl"
                          );
                          if ( !labelUrl.startsWith( "http" ) )
                          {
                            fetchSignedUrl( labelUrl );
                          }
                          else
                          {
                            window.open( labelUrl );
                          }
                        }}
                      />
                    )}
                  </Tab>
                  {!preventSellerOrPartnerFulfill && (
                    <Tab label="Billing & Shipping">
                      <Spacer />
                      <TableContainer>
                        <Typography variant="h6">Shipping Details</Typography>
                        <Spacer />
                        {shippingAddress ? (
                          <Table
                            aria-label="simple table"
                            className={classes.table}
                          >
                            <TableHead>
                              <TableRow className={classes.table}>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      Line 1
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      Line 2
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      City
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      State/Province
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      Country
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      ZIP/Postal Code
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      Phone
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      First Name
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      Last Name
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      Company
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {shippingAddress.line1}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {shippingAddress.line2}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {shippingAddress.city}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {shippingAddress.state}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {shippingAddress.country}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {shippingAddress.postalCode}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {shippingAddress.phone}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {shippingAddress.firstname}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {shippingAddress.lastname}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {shippingAddress.company}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        ) : (
                          <div>No Shipping Details Set</div>
                        )}
                        <Spacer />
                        <Typography variant="h6">Billing Details</Typography>
                        <Spacer />
                        {payment ? (
                          <Table
                            aria-label="simple table"
                            className={classes.table}
                          >
                            <TableHead>
                              <TableRow className={classes.table}>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      Payment Type
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      Card Source Country
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      Line 1
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      Line 2
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      City
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      State/Province
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      Country
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      ZIP/Postal Code
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                      Name
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {`${payment.brand || ""} ${
                                    payment.last4
                                      ? `••••${payment.last4}`
                                      : ""
                                  } ${
                                    payment.wallet?.name
                                      ? `(${payment.wallet.name})`
                                      : ""
                                  }`}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {payment.cardSourceCountry}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {billingAddress?.line1}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {billingAddress?.line2}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {billingAddress?.city}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {billingAddress?.state}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {billingAddress?.country}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {billingAddress?.postalCode}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {record?.customer?.billing?.name}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        ) : (
                          <div>No Payment Details Set</div>
                        )}
                      </TableContainer>
                    </Tab>
                  )}
                  <Tab label="Notes">
                    <Spacer />
                    {detailProperties.record.notes &&
                      detailProperties.record.notes.length > 0 ? (
                        <Notes
                          {...{
                            notesData: detailProperties.record.notes,
                            id: detailProperties.record.id,
                          }}
                        />
                      ) : (
                        <Notes
                          {...{ notesData: [], id: detailProperties.record.id }}
                        />
                      )}
                  </Tab>
                  <Tab label="Delivery">
                    <DeliveryHistoryTable
                      id={record.id}
                      targetResource="orders"
                    />
                  </Tab>
                  {hasPermissions(
                    [
                      BooleanRoles.Admin,
                      BooleanRoles.RefundCustomerService,
                      BooleanRoles.Fulfillment,
                      BooleanRoles.CustomerService,
                      BooleanRoles.Seller,
                    ],
                    permissions
                  ) &&
                      authentication && (
                    <Tab label="Authentication">
                      <Spacer />
                      <TableContainer>
                        <Spacer />
                        {authentication ? (
                          <Table
                            aria-label="simple table"
                            className={classes.table}
                          >
                            <TableHead>
                              <TableRow className={classes.table}>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                        Status
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                        Date
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                        Cost
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                        Currency
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                        Note
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  <Typography variant={"caption"}>
                                        User
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {authentication.status}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {authentication.timestamps &&
                                        authentication.timestamps[
                                          authentication.status
                                        ]}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {authentication.price &&
                                        formatPriceWithoutCurrency(
                                          authentication.price.toString()
                                        )}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {authentication.currency?.toUpperCase()}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {authentication.note}
                                </TableCell>
                                <TableCell
                                  className={classes.table}
                                  align="left"
                                >
                                  {authentication.userId}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        ) : (
                          <div>No Authentication for this order</div>
                        )}
                      </TableContainer>
                    </Tab>
                  )}

                  {hasPermissions(
                    [
                      BooleanRoles.Admin,
                      BooleanRoles.RefundCustomerService,
                      BooleanRoles.Fulfillment,
                      BooleanRoles.CustomerService,
                    ],
                    permissions
                  ) &&
                      record.refund && (
                    <Tab label="Refund">
                      <RefundDetailsTable
                        refund={{
                          ...record.refund,
                          refundTimestamp: record.timestamps.refunded,
                        }}
                      />
                    </Tab>
                  )}

                  {detailProperties.record.reviewRating && (
                    <Tab label="Rating and Review">
                      {!reviewDataLoading && review && (
                        <Box padding={2}>
                          <Grid container>
                            <Grid item xs={6} sm={6} md={6}>
                              <Typography
                                variant={"h6"}
                                style={{
                                  textTransform: "capitalize",
                                  marginBottom: "20px",
                                }}
                                className={classes.reviewRow}
                              >{`Status: ${review.status}`}</Typography>

                              <DateField
                                label="Date: "
                                date={
                                    review.timestamps.created as unknown as Date
                                }
                                className={classes.reviewRow}
                              />

                              <Typography
                                variant={"h6"}
                                className={classes.reviewRow}
                              >{`Rating: ${review.stars} / 5`}</Typography>

                              {review.comment && (
                                <>
                                  <Typography
                                    variant={"h6"}
                                    className={classes.reviewRow}
                                  >{`Comments:`}</Typography>
                                  <p style={{ margin: "0" }}>
                                    {review.comment}
                                  </p>
                                </>
                              )}
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            style={{ marginTop: "20px" }}
                          >
                            {hasPermissions(
                              [BooleanRoles.Admin],
                              permissions
                            ) && (
                              <Box textAlign="left">
                                {( review.status === "pending" ||
                                    review.status === "rejected" ) && (
                                  <Button
                                    className={[
                                      buttonClasses.saveButton,
                                      classes.button,
                                    ].join( " " )}
                                    disabled={approveLoading || rejectLoading}
                                    onClick={approveReview}
                                  >
                                      Approve
                                  </Button>
                                )}

                                {( review.status === "pending" ||
                                    review.status === "approved" ) && (
                                  <Button
                                    className={[
                                      buttonClasses.saveButton,
                                      classes.button,
                                    ].join( " " )}
                                    disabled={approveLoading || rejectLoading}
                                    onClick={rejectReview}
                                  >
                                      Reject
                                  </Button>
                                )}
                              </Box>
                            )}
                          </Grid>

                          {review.status !== "pending" && review.history && (
                            <>
                              <hr />
                              <TableContainer>
                                <Table style={{ marginBottom: "20px" }}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="left">
                                        <Typography
                                          align="left"
                                          noWrap={true}
                                        >
                                            Date
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="left">
                                        <Typography align="left">
                                            Author
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="left">
                                        <Typography>Action</Typography>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {review.history.map( ( history ) => (
                                      <TableRow key={history.id}>
                                        <TableCell width={250} align="left">
                                          <Typography
                                            align="left"
                                            noWrap={true}
                                          >
                                            {history.timestamp ? (
                                              <>
                                                {new Date(
                                                  history.timestamp
                                                ).toLocaleString( "en-US", {
                                                  year: "numeric",
                                                  month: "long",
                                                  day: "numeric",
                                                } )}
                                                <br />
                                                {new Date(
                                                  history.timestamp
                                                ).toLocaleString( "en-US", {
                                                  hour: "numeric",
                                                  minute: "numeric",
                                                  second: "numeric",
                                                } )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </Typography>
                                        </TableCell>
                                        <TableCell width={250} align="left">
                                          <Typography
                                            align="left"
                                            noWrap={true}
                                          >
                                            {`${history.reviewer.firstname} ${history.reviewer.lastname}`}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <Typography
                                            align="left"
                                            noWrap={true}
                                          >
                                            {history.status}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    ) )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          )}
                        </Box>
                      )}
                    </Tab>
                  )}
                  {hasPermissions( [BooleanRoles.Admin], permissions ) &&
                      detailProperties.record.timestamps
                        .latestTransferFailure && (
                    <Tab label="Failed Transfers">
                      <FailedTransfer
                        id={record.id}
                        targetResource="orders"
                      />
                    </Tab>
                  )}
                  {detailProperties.record.disputes &&
                      detailProperties.record.disputes.length > 0 && (
                    <Tab label="DISPUTES">
                      <TableContainer>
                        {detailProperties.record.disputeNote && (
                          <DisputeNote
                            note={detailProperties.record.disputeNote}
                          />
                        )}
                        <Spacer />
                        <Typography variant="h6">
                              Dispute Details
                        </Typography>
                        <Spacer />
                        <Table
                          aria-label="simple table"
                          className={classes.table}
                        >
                          <TableHead>
                            <TableRow className={classes.table}>
                              <TableCell
                                className={classes.table}
                                width={250}
                                align="left"
                              >
                                <Typography variant={"caption"}>
                                      Status
                                </Typography>
                              </TableCell>
                              <TableCell
                                className={classes.table}
                                width={280}
                                align="left"
                              >
                                <Typography variant={"caption"}>
                                      Reason
                                </Typography>
                              </TableCell>
                              <TableCell
                                className={classes.table}
                                align="left"
                              >
                                <Typography variant={"caption"}>
                                      Date
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {detailProperties.record.disputes.map(
                              ( dispute ) => (
                                <TableRow key={dispute.id}>
                                  <TableCell
                                    className={classes.table}
                                    align="left"
                                  >
                                    {dispute.status}
                                  </TableCell>
                                  <TableCell
                                    className={classes.table}
                                    align="left"
                                  >
                                    {dispute.reason}
                                    <br />
                                    {dispute.event || " "}
                                  </TableCell>
                                  <TableCell
                                    className={classes.table}
                                    align="left"
                                  >
                                    {dispute.timestamps.created
                                      ? new Date(
                                        dispute.timestamps.created
                                      ).toLocaleString( "en-US", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                        second: "numeric",
                                      } )
                                      : " "}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Tab>
                  )}
                  {hasInitiatedReturns && (
                    <Tab label="Returns">
                      <ReturnHistoryTable
                        id={record.id}
                        targetResource="orders"
                      />
                    </Tab>
                  )}
                </TabbedShowLayout>
              </>
            )}
          </CardContent>
        </Card>
      </DymoProvider>
    </LaunchDarklyProvider>
  );
};
