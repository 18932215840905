import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Grid,
  Typography,
  makeStyles
} from "@material-ui/core";
import {
  BooleanField,
  Create,
  DateField,
  SimpleForm,
  TextInput
} from "ra-ui-materialui";
import { ResourceComponentProps, useGetList, useNotify } from "ra-core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Tab, TabbedShowLayout, TabbedShowLayoutTabs } from "react-admin";

const useStyles = makeStyles( {
  card: {
    margin: "10px",
  },
  "border-top": {
    borderTop: "thin solid lightgrey",
  },
  "primary-text": {
    fontWeight: "bold",
  },
  "secondary-text": {
    fontWeight: "normal",
    fontSize: "x-small",
    // padding: "0rem 1rem 0rem"
    "& span": {
      display: "inline",
      "& svg": {
        fontSize: "x-small",
      },
    },
  },
  "padding-1rem": {
    padding: "1rem",
  },
  "max-width": {
    maxWidth: "80%",
  },
  "left-padding": {
    paddingLeft: "8px",
  },
  "no-left-padding": {
    padding: "8px 0px",
  },
} );

interface EventRankData {
  isClosetSale: boolean;
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  handle: string;
  rank?: number;
  isCollaboration: boolean;
  partnership?: string;
  eventData?: EventRankData[];
}

const EventDetails = ( event: EventRankData ) =>
{
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={classes["primary-text"]} id={event.id + "-name"}>
          {event.name}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography className={classes["secondary-text"]} id={event.id + "-id"}>
          Event Id: {event.id}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography
          className={classes["secondary-text"]}
          id={event.id + "-collaboration"}
        >
          Is Collaboration:{" "}
          <BooleanField
            record={event}
            source="isCollaboration"
            className={classes["secondary-text"]}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Typography
          className={classes["secondary-text"]}
          id={event.id + "-startDate"}
        >
          Start Date:{" "}
          <DateField
            record={event}
            source="startDate"
            showTime={true}
            className={classes["secondary-text"]}
          />
        </Typography>
      </Grid>
      {!event.isCollaboration && (
        <Grid item xs={12} sm={6} md={4}>
          <Typography
            className={classes["secondary-text"]}
            id={event.id + "-handle"}
          >
            Seller Handle: {event.handle}
          </Typography>
        </Grid>
      )}
      {!event.isCollaboration && (
        <Grid item xs={12} sm={6} md={4}>
          <Typography
            className={classes["secondary-text"]}
            id={event.id + "-closetSale"}
          >
            Is Closet Sale:{" "}
            <BooleanField
              record={{ ...event, isClosetSale: event.isClosetSale || false }}
              source="isClosetSale"
              className={classes["secondary-text"]}
            />
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={4}>
        <Typography
          className={classes["secondary-text"]}
          id={event.id + "-endDate"}
        >
          End Date:{" "}
          <DateField
            record={event}
            source="endDate"
            showTime={true}
            className={classes["secondary-text"]}
          />
        </Typography>
      </Grid>
      {!event.isCollaboration && event.partnership && (
        <Grid item xs={12} sm={6} md={4}>
          <Typography
            className={classes["secondary-text"]}
            id={event.id + "-handle"}
          >
            Partnership: {event.partnership}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const EventDetailCard = ( properties: {
  event: EventRankData;
  includeInput: boolean;
} ) =>
{
  const classes = useStyles();
  const event = properties.event;

  return (
    <Card>
      <Grid container className={classes["padding-1rem"]}>
        {properties.includeInput && (
          <Grid item xs={4} sm={1}>
            <TextInput
              source={event.id + "-" + event.isCollaboration + "-rank"}
              label="Rank"
              defaultValue={event.rank}
              className={classes["max-width"]}
            />
          </Grid>
        )}
        <Grid item xs={8} sm={11} className={classes["no-left-padding"]}>
          <EventDetails {...event} />
        </Grid>
      </Grid>
    </Card>
  );
};

const EventAccordion = ( event: EventRankData ) =>
{
  const classes = useStyles();
  const [expand, setExpand] = React.useState( false );
  const toggleAccordion = () =>
  {
    setExpand( ( expand ) => !expand );
  };

  return (
    <Accordion expanded={expand} id={event.id + "-accordion"}>
      <AccordionSummary
        aria-controls="panel1a-content"
        id={event.id + "-accordionSummary"}
        expandIcon={<ExpandMoreIcon />}
        IconButtonProps={{
          size: "medium",
          onClick: toggleAccordion,
        }}
      >
        <Grid container>
          <Grid item xs={4} sm={1}>
            <TextInput
              source={event.id + "-" + event.isCollaboration + "-rank"}
              label="Rank"
              defaultValue={event.rank}
              className={classes["max-width"]}
            />
          </Grid>
          <Grid
            item
            xs={8}
            sm={11}
            className={classes["left-padding"]}
            onClick={toggleAccordion}
          >
            <EventDetails {...event} />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes["border-top"]}>
        <Grid container>
          {event.eventData &&
            event.eventData.length > 0 &&
            event.eventData.map( ( collabEventData ) =>
            {
              collabEventData.isCollaboration = false;

              return (
                <Grid
                  item
                  xs={12}
                  className={classes["padding-1rem"]}
                  key={event.id}
                >
                  <EventDetails {...collabEventData} />
                </Grid>
              );
            } )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const transform = ( data: { [id: string]: number } ) => ( {
  id: "",
  creation: {
    events: Object.keys( data ).map( ( key ) =>
    {
      const splitKey = key.split( "-" );
      return {
        id: splitKey[0],
        isCollaboration: splitKey[1] === "true",
        rank: data[key],
      };
    } ),
  },
} );

const EventRankCreate = (
  properties: ResourceComponentProps & { events: EventRankData[] }
): JSX.Element =>
{
  const { events, ...otherProperties } = properties;

  const notify = useNotify();
  const success = () =>
  {
    notify( "Event Ranks Updated" );
    setTimeout( () =>
    {
      window.location.reload();
    }, 1500 );
  };

  return (
    <Create
      {...otherProperties}
      transform={transform}
      onSuccess={success}
      title="Update Pinned Event Ranks"
    >
      <SimpleForm resource={"events-rank"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Update Pinned Event Ranks</Typography>
          </Grid>
          {events.map( ( event: EventRankData ) =>
          {
            //So there's two types of dropdowns
            if ( event.isCollaboration )
            {
              // Return a slightly different look for collaboration events
              return (
                <Grid item xs={12} key={event.id}>
                  <EventAccordion {...event} />
                </Grid>
              );
            }

            // Set the default isCollbaoration value
            // Might be nice to ensure that it is being returned from the backend.
            event.isCollaboration = false;

            return (
              <Grid item xs={12} key={event.id}>
                <EventDetailCard {...{ event, includeInput: true }} />
              </Grid>
            );
          } )}
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const EventRank = ( properties: ResourceComponentProps ): JSX.Element =>
{
  const [featuredEvents, setFeaturedEvents] = useState( [] as EventRankData[] );
  const [marketplaceEvents, setMarketplaceEvents] = useState(
    [] as EventRankData[]
  );
  const { data, loaded } = useGetList( "events-rank" );

  useEffect( () =>
  {
    if ( loaded && Object.values( data ).length > 0 )
    {
      const featured = [];
      const marketPlace = [];
      for ( const event of Object.values( data ) as EventRankData[] )
      {
        if ( event.isClosetSale === true )
        {
          marketPlace.push( event );
        }
        else
        {
          featured.push( event );
        }

        setFeaturedEvents( featured );
        setMarketplaceEvents( marketPlace );
      }
    }
  }, [loaded] );

  return (
    <TabbedShowLayout
      tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />}
    >
      <Tab label="Featured events">
        {loaded && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <EventRankCreate {...{ ...properties, events: featuredEvents }} />
            </Grid>
          </Grid>
        )}
      </Tab>
      <Tab label="Marketplace events">
        {loaded && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <EventRankCreate
                {...{ ...properties, events: marketplaceEvents }}
              />
            </Grid>
          </Grid>
        )}
      </Tab>
    </TabbedShowLayout>
  );
};
