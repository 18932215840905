import { FulfillmentType } from "./shared";
import { IStorageMedia } from "../../components/order/notes";

export interface Fulfillment {
  carrier: string;
  customerNotes: string;
  trackingNumber: string;
  trackingLink: string;
}

export interface FulfillmentItems {
  skuId: string;
  quantity: number;
}
export interface Fulfillments {
  productInventoryItemId?: string;
  externalId: string;
  location: string;
  status: string;
  items: FulfillmentItems[];
  barcodeAssignment?: Assignor;
  locationAssignment?: Assignor;
  returnShippingLabel?: {
    carrier: string;
    labelProvider: {
      currency: string;
      labelFee: string;
      labelUrl: string;
      shipmentId: string;
      rateId: string;
    };
    trackingNumber: string;
  };
  returnStatus?: string;
  timestamps?: {
    initiated?: Date;
  };
}

export interface FirebaseTimestamp {
  _seconds: number;
  _nanoseconds: number;
}
export interface Assignor {
  assignedAt: FirebaseTimestamp;
  assignedBy: string;
  customerId: string;
}
export interface Billing {
  payment: Payment;
  name: string;
  address: Address;
}
export interface Wallet {
  [key: string]: string | Record<string, unknown>;
}
export interface Payment {
  type: string;
  last4: string;
  brand: string;
  wallet?: Wallet;
  cardSourceCountry: "CA" | "US";
}
export interface Address {
  lastname: string;
  line1: string;
  line2?: string;
  phone: string;
  postalCode: string;
  firstname: string;
  city: string;
  state: string;
  country: string;
  company?: string;
  oneLiner?: string;
}

export interface CustomerLogistics {
  type: string;
  option: string;
  zone: string;
  address: Address;
}

export interface Customer {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  company: string;
  phone: string;
  logistics: CustomerLogistics;
  billing: Billing;
  passedFulfillmentReview: boolean;
  vip: boolean;
}

export interface Seller {
  id: string;
  event: string;
  handle: string;
}

export interface CouponData {
  code: string;
  couponId: string;
  discountType: string;
  redeemedOn: string;
  targetAmountInCents: number;
  targetField: string;
  minimumAmount?: string;
}

export interface OrderPackageProtectionSettings {
  appliedAmount: number;
  appliedType: string;
  isApplied: boolean;
}

export interface Specifications {
  currency: string;
  taxRate: number;
  shippingDeadline: number;
  event: string;
  coupon?: CouponData;
  isTransactionFee?: boolean;
  packageProtectionSettings?: OrderPackageProtectionSettings;
}
export interface Details {
  address: Address;
  phone: string;
  firstname: string;
  company: string;
  lastname: string;
}

export interface OrderLogistics {
  details: Details;
  zone: string;
  option: string;
  type: string;
  delivery: boolean;
  description: string;
  fulfillment?: {
    carrier: string;
    customerNotes: string;
    trackingNumber: string;
    fulfillmentService?: FulfillmentType;
    labelProvider?: {
      labelUrl: string;
    };
  };
}

export interface Medium {
  rawSmallPosterPath: string;
  rawMediaUploaded: boolean;
  mediaWidth: number;
  type: string;
  rawPosterUploaded: boolean;
  rawSmallPosterUploaded: boolean;
  rawMediaPath: string;
  mediaHeight: number;
  rawPosterPath: string;
  productPoster: string;
  productVideo: string;
  rawPosterUrl: string;
}

export interface Item {
  productInventoryItemId?: string;
  description: string;
  media: Medium[];
  brandName: string;
  skuId: string;
  sourceCountry: string;
  currentVersion: number;
  quantity: number;
  productId: string;
  salePrice: string;
  productName: string;
  productDescription: string;
  isFinalSale: boolean;
  productTags: string[];
  location?: string;
  externalProductId?: string;
}

export interface Cart {
  quantity: number;
  items: Item[];
}

export interface Totals {
  serviceTax: number;
  fee: string;
  tax: string;
  subtotal: string;
  totalPay: string;
  shippingTax: number;
  shipping: string;
  retailerPay: string;
  productSubtotalTax: string;
  totalDiscount: string;
  packageProtection?: string;
  packageProtectionTax?: string;
}

export interface Pay {
  url: string;
  test_card: string;
}

interface Timestamps {
  updated: Date;
  created: Date;
  fulfilled?: Date;
  confirmed?: Date;
  paid?: Date;
  refunded?: Date;
  latestTransferFailure?: Date;
}

export interface Refund {
  id: string;
  amount: number;
  currency: "cad" | "usd";
  reverseTransfer?: boolean;
  addProcessingFee?: boolean;
  restOfTheProcessingFeeAmount?: number;
  refundCategory?: string;
  notes?: string;
  refundTimestamp?: Date;
}

export type OrderStatus =
  | "created"
  | "paid"
  | "confirmed"
  | "pending"
  | "fulfilled"
  | "refunded"
  | "cancelled"
  | "disputeLost"
  | "error";

export interface noteData {
  id: string;
  note: string;
  author: string;
  medias: Omit<IStorageMedia, "rawPosterPath">[];
  timestamps: Timestamps;
}

export interface disputeData {
  id: string;
  timestamps: Timestamps;
  reason: string;
  status: string;
  event: string;
}

export interface DisputeNote {
  note: string;
  author: string;
  timestamps: Timestamps;
}

export enum AuthenticationStatus {
  PENDING = "pending",
  AUTHENTICATED = "authenticated",
  REJECTED = "rejected",
}

type AuthenticationTimestamps = {
  [key in AuthenticationStatus]: Date;
};
export interface Authentication {
  status: AuthenticationStatus;
  authenticationShippingLabelSellerToShopthing?: {
    carrier: string;
    labelProvider: {
      currency: string;
      labelFee: string;
      labelUrl: string;
      shipmentId: string;
      rateId: string;
    };
    trackingNumber: string;
  };
  authenticationShippingLabelShopthingToSeller?: {
    carrier: string;
    labelProvider: {
      currency: string;
      labelFee: string;
      labelUrl: string;
      shipmentId: string;
      rateId: string;
    };
    trackingNumber: string;
  };
  timestamps: AuthenticationTimestamps;
  price: number;
  currency: string;
  note: string;
  userId: string;
}

export interface Order {
  sellerType: string;
  productInventoryItemId?: string;
  id: string;
  status: OrderStatus;
  customer: Customer;
  seller: Seller;
  specifications: Specifications;
  logistics: OrderLogistics;
  cart: Cart;
  totals: Totals;
  pay: Pay;
  fulfillmentIds: string[];
  returns?: Returns;
  fulfillments: Record<string, Fulfillments>;
  fulfilledBy: "ShopThing" | "Seller" | "Partner";
  timestamps: Timestamps;
  isArchived: boolean;
  refund?: Refund;
  billingMatch?: boolean;
  billingAndShippingMatch: boolean;
  notes: noteData[];
  latestDisputeStatus: string;
  disputeResolved: boolean;
  disputes: disputeData[];
  disputeNote: DisputeNote;
  source: string;
  isOrderCloned?: boolean;
  reviewRating?: number;
  authentication?: Authentication;
  allowSellerToSelfFulfill?: boolean;
  hasTransferFailure?: boolean;
}

export interface ReturnHistoryData {
  author: string;
  fulfillmentId: string;
  id: string;
  note: string;
  productInventoryItemId: string;
  reason: string;
  returnStatus: string;
  timestamp: string;
}

export interface Returns {
  fulfillments: number[];
  status: string[];
  returnLocation?: string;
  timestamps?: { initiated?: Date };
  returnStatus?: string;
}

export enum ReviewStatus {
  pending = "pending",
  approved = "approved",
  rejected = "rejected",
}

export interface ReviewerI {
  id: string;
  firstname: string;
  lastname: string;
}

export interface Review {
  id?: string;
  comment?: string;
  stars: number;
  status: ReviewStatus;
  reviewer?: ReviewerI;
  orderId: string;
  customer: {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    vip?: boolean;
  };
  seller: {
    id: string;
    event: string;
    handle: string;
  };
  product: {
    id: string;
    brandName: string;
    productName: string;
    tagIds?: string[];
  };
  timestamps: {
    created: string;
    approved?: string;
    rejected?: string;
  };
  history?: {
    status: ReviewStatus;
    reviewer: ReviewerI;
    timestamp: string;
    id: string;
  }[];
}
