import { useDataProvider } from "react-admin";
import * as React from "react";
import { useEffect, useState } from "react";
import { Spacer } from "../generics/spacer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { formatPrice } from "../../utils/primitive";

const useStyles = makeStyles( () =>
  createStyles( {
    fullWidth: {
      width: "100%",
    },
    table: {},
  } )
);

interface FailedTransferHistoryProperties {
  id: string;
  targetResource: string;
}

interface FailedTransferHistoryData {
  reason: string;
  type: string;
  amount?: number;
  currency?: string;
  notes?: string;
}

export const FailedTransferHistoryTable: React.FunctionComponent<
  FailedTransferHistoryProperties
> = ( properties: FailedTransferHistoryProperties ) =>
{
  const dataProvider = useDataProvider();
  const [failedTransferHistory, setFailedTransferHistory] = useState( [] );
  const classes = useStyles();

  useEffect( () =>
  {
    dataProvider
      .getFailedTransferHistory( properties.targetResource, {
        id: properties.id,
      } )
      .then( ( { data }: { data: FailedTransferHistoryData[] } ) =>
      {
        setFailedTransferHistory( data );
      } );
  }, [] );

  return (
    <>
      <Spacer />
      <Typography variant={"h5"}>Failed Transfer History</Typography>
      <Spacer />
      <TableContainer>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow className={classes.table}>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Update date</Typography>
              </TableCell>
              <TableCell className={classes.table} width={150} align="left">
                <Typography variant={"caption"}>Type</Typography>
              </TableCell>
              <TableCell className={classes.table} width={150} align="left">
                <Typography variant={"caption"}>Amount</Typography>
              </TableCell>
              <TableCell className={classes.table} width={250} align="left">
                <Typography variant={"caption"}>Notes</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {failedTransferHistory.map( ( entry ) => (
              <TableRow key={entry.id}>
                <TableCell className={classes.table} align="left">
                  {entry.timestamp
                    ? new Date( entry.timestamp ).toLocaleString( "en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    } )
                    : " "}
                </TableCell>
                <TableCell className={classes.table} align="left">
                  {entry.type}
                </TableCell>
                <TableCell className={classes.table} align="left">
                  {entry.amount
                    ? formatPrice( entry.amount, entry.currency )
                    : ""}
                </TableCell>
                <TableCell className={classes.table} align="left">
                  {entry.reason}
                </TableCell>
              </TableRow>
            ) )}
          </TableBody>
        </Table>
      </TableContainer>
      <Spacer />
    </>
  );
};
